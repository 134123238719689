import React from "react"
import Slider from "react-slick"
import { Fade } from "react-awesome-reveal"

const BuildingLogInfo = ({ imageSide, images, date, title, content }) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1
  }

  return (
    <section className="building-log-info">
      <div className="container">
        <div
          className={`row building-log-info__holder ${
            imageSide === "right" ? "building-log-info__holder--revert" : ""
          }`}
        >
          {imageSide === "left" && (
            <div className="col-12 col-lg-6 col-xxl-5 building-log-info__slider-holder">
              <Fade delay={150} direction={"left"} triggerOnce>
                <Slider
                  {...settings}
                  className="building-log-info__slider image-shadow"
                  style={{ left: 0 }}
                >
                  {images.map((item, index) => (
                    <img
                      data-src={item}
                      alt=""
                      className="building-log-info__image lazyload"
                      key={index}
                    />
                  ))}
                </Slider>
              </Fade>
            </div>
          )}
          <div className={`col-12 col-lg-6 col-xl-5 offset-xxl-1 building-log-info__title-holder ${imageSide === "left" ? 'offset-xl-1' : '' }`}>
            <Fade delay={150} direction={ imageSide === "right" ? "left" : "right" } triggerOnce>
              <p className="h2 font-regular title-holder__date">{date}</p>
              <h2 className="mb-70 title-holder__title">{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }} className="title-holder__content"></div>
            </Fade>
          </div>
          {imageSide === "right" && (
            <div className={`col-12 col-lg-6 col-xxl-5 offset-xl-1 building-log-info__slider-holder `}>
              <Fade delay={150} direction={"right"} triggerOnce>
                <Slider
                  {...settings}
                  className="building-log-info__slider image-shadow"
                  style={{ left: 0 }}
                >
                  {images.map((item, index) => (
                    <img
                      data-src={item}
                      alt=""
                      className="building-log-info__image lazyload"
                      key={index}
                    />
                  ))}
                </Slider>
              </Fade>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
export default BuildingLogInfo
