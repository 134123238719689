/* eslint-disable array-callback-return */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import BuildingLogInfo from "../components/buildingLogInfo"

const BuildingLog = () => {
  const AcfQuery = useStaticQuery(
    graphql`
      query BuildingLogQuery {
        allWordpressAcfPages(filter: { wordpress_id: { eq: 536 } }) {
          edges {
            node {
              id
              acf {
                building_log {
                  title
                  date
                  content
                  images {
                    item {
                      localFile {
                        url
                      }
                    }
                  }
                }
              }
              wordpress_id
            }
          }
        }
      }
    `
  )

  const data = AcfQuery.allWordpressAcfPages.edges[0].node.acf

  return (
    <Layout>
      <SEO title="Dziennik budowy" />
      <Banner title="Dziennik budowy" />
      {data.building_log.map((item, index) => {
        let images = []
        item.images.map(item => {
          if (item.item !== null) {
            images.push(item.item.localFile.url)
          }
        })
        return (
          <BuildingLogInfo
            key={index}
            imageSide={index % 2 ? "right" : "left"}
            images={images}
            date={item.date}
            title={item.title}
            content={item.content}
          />
        )
      })}
      {/* <BuildingLogInfo 
                imageSide="left"
                images={ 
                    [
                        require('../assets/img/building-log1.jpg'),
                        require('../assets/img/building-log1.jpg')
                    ] 
                }
                date="01.07.2020"
                title="Etap 1"
                content='
                    <p class="mb-40">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                        ultricies vel, imperdiet sit amet mauris. Phasellus tincidunt ut felis ac blandit. 
                        Vestibulum varius metus quis orci lacinia finibus. Morbi vel dolor nec massa varius 
                        interdum. Cras eu libero pellentesque, vulputate dolor ac, molestie tellus.
                    </p>
                    <p class="mb-40">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                        ultricies vel, imperdiet sit amet mauris. Phasellus tincidunt ut felis ac blandit. 
                        Vestibulum varius metus quis orci lacinia finibus. Morbi vel dolor nec massa varius 
                        interdum. Cras eu libero pellentesque, vulputate dolor ac, molestie tellus.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                    </p>
                '
            />
            <BuildingLogInfo 
                imageSide="right"
                images={ 
                    [
                        require('../assets/img/building-log1.jpg'),
                        require('../assets/img/building-log1.jpg')
                    ] 
                }
                date="01.07.2020"
                title="Etap 1"
                content='
                    <p class="mb-40">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                        ultricies vel, imperdiet sit amet mauris. Phasellus tincidunt ut felis ac blandit. 
                        Vestibulum varius metus quis orci lacinia finibus. Morbi vel dolor nec massa varius 
                        interdum. Cras eu libero pellentesque, vulputate dolor ac, molestie tellus.
                    </p>
                    <p class="mb-40">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                        ultricies vel, imperdiet sit amet mauris. Phasellus tincidunt ut felis ac blandit. 
                        Vestibulum varius metus quis orci lacinia finibus. Morbi vel dolor nec massa varius 
                        interdum. Cras eu libero pellentesque, vulputate dolor ac, molestie tellus.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                    </p>
                '
            />
            <BuildingLogInfo 
                imageSide="left"
                images={ 
                    [
                        require('../assets/img/building-log1.jpg'),
                        require('../assets/img/building-log1.jpg')
                    ] 
                }
                date="01.07.2020"
                title="Etap 1"
                content='
                    <p class="mb-40">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                        ultricies vel, imperdiet sit amet mauris. Phasellus tincidunt ut felis ac blandit. 
                        Vestibulum varius metus quis orci lacinia finibus. Morbi vel dolor nec massa varius 
                        interdum. Cras eu libero pellentesque, vulputate dolor ac, molestie tellus.
                    </p>
                    <p class="mb-40">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                        ultricies vel, imperdiet sit amet mauris. Phasellus tincidunt ut felis ac blandit. 
                        Vestibulum varius metus quis orci lacinia finibus. Morbi vel dolor nec massa varius 
                        interdum. Cras eu libero pellentesque, vulputate dolor ac, molestie tellus.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in interdum ex. Ut neque 
                        tellus, suscipit in elit sed, ultricies tempor urna. Curabitur tellus lorem, euismod non 
                    </p>
                '
            /> */}
    </Layout>
  )
}

export default BuildingLog
